import { projection } from '@src/build/queries/projection';
import { sanityQueries } from '@src/build/queries';

const queryTypes = {
  landingPage: projection.standalonePage,
  shopifyProduct: {
    main: projection.product,
    servicesPoliciesInfo: `"servicesPoliciesInfo": *[_type == "servicesPoliciesInfo"] | order(orderRank asc) [0] {
      _type,
      items[] {
        icon {
          svg,
        },
        text,
      }
    }`,
  },
  productGroup: {
    main: 
    `...,
    subcategories[] -> {
      ...,
      slug {
        current,
        _type,
      },
      title,
      visibilityRegions,
      id,
    }`,
    productsListing: `"productsListing": ${sanityQueries.productsListingQuery}`,
    productGroups: `"productGroups": ${sanityQueries.productGroupsQuery}`,
  },
  category: {
    main: `...,
    group == "blog" => {
      "pageEntry": ${sanityQueries.blogIndexQuery},
      "categories": ${sanityQueries.blogCategoriesQuery},
      "entries": ${sanityQueries.blogPostsQuery}
    },
    group != "blog" => {
      "pageEntry": ${sanityQueries.resourceIndexQuery},
      "categories": ${sanityQueries.resourceCategoriesQuery},
      "entries": ${sanityQueries.resourcePostsQuery}
    },
    "hasTopPadding": 'false'`,
  },
  blogPost: {
    main: `${projection.blogPost}
    "posts": ${sanityQueries.blogPostsQuery}`
  },
  resourcePost: {
    main: `${projection.resourcePost}
    "posts": ${sanityQueries.resourcePostsQuery}`
  },
  simpleLandingPage: {
    main: `_id == "productsIndex" => {
      ...,
      subcategories[] -> {
        ...,
        slug {
          current,
          _type,
        },
        title,
        visibilityRegions,
        id,
      },
      "productsListing": ${sanityQueries.productsListingQuery},
      "productGroups": ${sanityQueries.productGroupsQuery}
    },
    _id == "blogIndex" => {
      ${projection.blogIndex},
      "categories": ${sanityQueries.blogCategoriesQuery},
      "entries": ${sanityQueries.blogPostsQuery},
    },
    _id == "resourcesIndex" => {
     ${projection.resourceIndex},
      "categories": ${sanityQueries.resourceCategoriesQuery},
      "entries": ${sanityQueries.resourcePostsQuery},
    },
    _id == "reviews" => {
      ${projection.standalonePage}
    }`
  }
}

export const getSimpleLandingPageDetails = (document, components) => {
  const {
    CollectionsPage,
    BlogIndex,
    ResourcesIndex,
    StandalonePage,
    Empty
  } = components;

  const componentsForEachId = [
    {
      id: "productsIndex",
      Component: CollectionsPage,
      props: {
        data: {
          documentId: `/(drafts.|)productsIndex}/`,
          pageEntry: [document],
          productGroups: {
            nodes: document?.productGroups?.nodes?.reduce((acc, item) => {
              const idWithoutDrafts = item?._id?.replace(/^drafts\./, '');
              if (!acc.some(existingItem => existingItem?._id?.replace(/^drafts\./, '') === idWithoutDrafts)) {
                acc.push(item);
              }
              return acc;
            }, [])
          },
          productEntries: document?.productsListing,
          // productsShopify: products,
        }
      }
    },
    {
      id: "blogIndex",
      Component: BlogIndex,
      props: {
        pageEntry: [document],
        categories: document?.categories,
        entries: document?.entries,
      }
    },
    {
      id: "resourcesIndex",
      Component: ResourcesIndex,
      props: {
        pageEntry: [document],
        categories: document?.categories,
        entries: document?.entries,
        categoryDocumentIds: [],
      }
    },
    {
      id: "reviews",
      Component: StandalonePage,
      props: {
        documentId: document?._id,
        page: document,
      }
    }
  ]
  
  return componentsForEachId.find(obj => obj.id === document?._id || obj.id === "drafts." + document?._id) || {
    Component: Empty,
    props: {}
  };
}

export const documentByIdQuery = (documentId, documentType) => `*[_id == "${documentId}"][0] {
  ${typeof queryTypes[documentType] === 'object' ? Object.values(queryTypes[documentType]) : queryTypes[documentType]},
}`;

export const getRelatedPosts = (posts) => {
  return posts?.filter(post => post?.categories?.map(category => category?.slug?.current)?.includes(post?.categories ? post?.categories[0]?.slug?.current : null))?.slice(0,2);
}
